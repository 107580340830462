import type * as CSS from "csstype";
import type { CSSProperties, HTMLAttributes } from "react";
import { Box } from "sparta";

interface IWebBlock extends HTMLAttributes<HTMLDivElement> {
  color?: CSS.Property.Color;
  center?: boolean;
}

export default function WebBlock({ color, children, style, center, ...props }: IWebBlock) {
  // check if we want to center style block
  const centeredStyle: CSSProperties = {};
  const centeredInner: CSSProperties = {};
  if (center) {
    // assign values
    centeredStyle.justifyContent = "center";
    centeredStyle.alignItems = "center";

    // inner values
    centeredInner.display = "flex";
    centeredInner.alignItems = "center";
    centeredInner.justifyContent = "center";
    centeredInner.flexDirection = "column";
  }

  return (
    <div {...props} style={{ ...style, ...centeredStyle, backgroundColor: color }} className="web-block">
      <div style={centeredInner}>{children}</div>
    </div>
  );
}
